/* Customize the scrollbar for webkit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 0px; /* Width of the scrollbar */
  height: 0px; /* Height of the scrollbar */
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 10px;
  width: 2px; /* Round the corners */
}

/* Handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the handle */
  border-radius: 10px; /* Round the corners */
  width: 2px;
}

/* Handle hover effect */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color when hovered */
}

/* For Firefox */
html {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Handle color and track color */
  scroll-behavior: smooth;
}

/* For Edge and Internet Explorer */
body {
  -ms-overflow-style: none; /* Hide default scrollbar */
}

body::-webkit-scrollbar {
  display: none; /* Hide default scrollbar */
}

.hoverCard {
  transition: transform 5s ease-in-out 1s; /* Apply transition to transform property */
}

.hoverCard:hover {
  transform: scale(1.05); /* Scale the card on hover */
}

#button:hover{
  background-color:#113;
  transition: 1s ease-out;
}

.header{
  color:rgb(79 79 138);
}

.wrap
{
    width: 320px;
    max-height: 250px;
    padding: 0;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.frame
{
    width: 1280px;
    height: 1000px;
    border: 0;

    -ms-transform: scale(0.25);
    -moz-transform: scale(0.25);
    -o-transform: scale(0.25);
    -webkit-transform: scale(0.25);
    transform: scale(0.25);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}